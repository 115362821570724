body {
  margin: 0;
  background: radial-gradient(circle at center, #ccd3de 0%, white 80%);
  backdrop-filter: blur(10px);
  /* background-color: white; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#circle-1 {
  position: absolute;
  width: 500px;
  height: 500px;
  z-index: -20;
  background-color: #ccd3de;
  opacity: 0.15;
  border-radius: 50%;
  top: 2%;
  left: 5%;
  transform: translate(-50%, -50%);
}

#circle-2 {
  position: absolute;
  width: 500px;
  height: 500px;
  z-index: -20;
  background-color: #ccd3de;
  opacity: 0.15;
  border-radius: 50%;
  top: 15%;
  left: 85%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

@media (max-width: 1520px) {
  #circle-2 {
    width: 420px;
    height: 420px;
  }
}

@media (max-width: 768px) {
  #circle-2 {
    display: none;
  }
}