.App {
  /* text-align: center; */
  width: 100%;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin: auto;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.first-div-class {
  background: radial-gradient(circle at center, #ccd3de 0%, white 120%);
  /* Use appropriate prefixes for browser compatibility */
  backdrop-filter: blur(10px);
}

.app-demo-container {
  background: linear-gradient(to bottom right, #373d47, #1b1e24 60%);
  height: 560px;
  width: 360px;
}

.drawer-open-button {
  display: 'none';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #737373;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #737373;
}
